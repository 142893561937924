<template>
  <b-form v-on:submit.prevent="save">
    <div class="mb-5">
      <AppBgLightAlert>
        <template #icon>
          <span class="svg-icon svg-icon-primary svg-icon-2x">
            <inline-svg src="/media/svg/icons/Home/Building.svg" />
            <!--end::Svg Icon-->
          </span>
        </template>
        <div class="d-flex flex-column">
          <span
            ><strong>{{ $t("SCHOOLDETAILS.MESSAGE.MES1") }}</strong></span
          >

          <span>
            {{ $t("SCHOOLDETAILS.MESSAGE.MES2") }}
          </span>
        </div>
      </AppBgLightAlert>
    </div>

    <b-form-group :label="$t('SCHOOLDETAILS.DETAILS.CODE')" label-cols-lg="2">
      <b-form-input
        v-model="$v.form.code.$model"
        :state="validateState('code')"
      ></b-form-input>
    </b-form-group>

    <b-form-group :label="$t('SCHOOLDETAILS.DETAILS.NAME')" label-cols-lg="2">
      <b-form-input
        v-model="$v.form.name.$model"
        :state="validateState('name')"
      ></b-form-input>
    </b-form-group>

    <b-form-group :label="$t('SCHOOLDETAILS.DETAILS.EMAIL')" label-cols-lg="2">
      <b-form-input
        v-model="$v.form.email.$model"
        :state="validateState('email')"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      :label="$t('SCHOOLDETAILS.DETAILS.CONTACTNO')"
      label-cols-lg="2"
    >
      <b-form-input
        v-model="$v.form.contact_number.$model"
        :state="validateState('contact_number')"
      ></b-form-input>
    </b-form-group>

    <div class="d-flex justify-content-end pt-3">
      <b-button variant="primary" type="submit">
        {{ $t("USERS.DETAILS.SUBMIT") }}
      </b-button>
    </div>
  </b-form>
</template>

<script>
import AppBgLightAlert from "@/modules/core/components/utility/info/AppBgLightAlert";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "SchoolInfoForm",
  components: { AppBgLightAlert },
  mixins: [validationMixin],
  props: {
    initialForm: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        name: "",
        code: "",
        contact_number: "",
        email: "",
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      code: {
        required,
      },
      contact_number: { required },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const submitForm = {
        id: this.initialForm.id,
        name: this.form.name,
        code: this.form.code,
        contact_number: this.form.contact_number,
        email: this.form.email,
      };

      console.log("Submit Form", submitForm);

      this.$emit("onSubmitClick", submitForm);
    },
  },
  watch: {
    initialForm(newForm) {
      console.log("Check form", newForm);
      if (newForm !== null) {
        this.form.name = newForm.organisation.name;
        this.form.code = newForm.organisation.registration_code;
        this.form.contact_number = newForm.organisation.phone;
        this.form.email = newForm.organisation.email;
      }
    },
  },
};
</script>

<style scoped></style>
