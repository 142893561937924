<template>
  <div class="d-flex alert alert-light px-4 py-3">
    <div class="my-auto">
      <slot name="icon"> </slot>
    </div>
    <div class="pl-4">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppBgLightAlert",
};
</script>

<style scoped></style>
