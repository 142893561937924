<template>
  <AppPageCard>
    <template #card-title>
      {{ $t("SCHOOLDETAILS.MESSAGE.SCHOOLDETAILS") }}
    </template>

    <SchoolInfoForm
      :initial-form="info"
      @onSubmitClick="submitForm"
    ></SchoolInfoForm>
  </AppPageCard>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import SchoolInfoForm from "@/modules/school/components/management/base/info/SchoolInfoForm";
import { mapGetters } from "vuex";

export default {
  name: "SchoolManagementPage",
  components: { SchoolInfoForm, AppPageCard },
  data() {
    return {
      isBusy: false,
    };
  },
  methods: {
    fetchForm() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchSchoolManagementForm")
        .finally(() => (this.isBusy = false));
    },
    submitForm(form) {
      console.log("Form ", form);

      this.$store
        .dispatch("updateSchoolManagementForm", form)
        .then((res) => {
          this.$bvModal.msgBoxOk(this.$t("ALERT.SUCCESS"));
          this.$emit("formSubmitted", res);
        })
        .catch(() => {
          this.$bvModal.msgBoxOk(this.$t("ALERT.ERROR"), {
            okVariant: "danger",
          });
        });
    },
  },
  mounted() {
    this.fetchForm();
  },
  computed: {
    ...mapGetters({
      info: "getSchoolManagementForm",
    }),
  },
};
</script>

<style scoped></style>
